exports.components = {
  "component---src-pages-availability-tsx": () => import("./../../../src/pages/availability.tsx" /* webpackChunkName: "component---src-pages-availability-tsx" */),
  "component---src-pages-certificates-tsx": () => import("./../../../src/pages/certificates.tsx" /* webpackChunkName: "component---src-pages-certificates-tsx" */),
  "component---src-pages-curriculum-vitae-tsx": () => import("./../../../src/pages/curriculum-vitae.tsx" /* webpackChunkName: "component---src-pages-curriculum-vitae-tsx" */),
  "component---src-pages-education-hetic-tsx": () => import("./../../../src/pages/education/hetic.tsx" /* webpackChunkName: "component---src-pages-education-hetic-tsx" */),
  "component---src-pages-education-mmi-tsx": () => import("./../../../src/pages/education/mmi.tsx" /* webpackChunkName: "component---src-pages-education-mmi-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-aquanatura-tsx": () => import("./../../../src/pages/jobs/aquanatura.tsx" /* webpackChunkName: "component---src-pages-jobs-aquanatura-tsx" */),
  "component---src-pages-jobs-bl-0-x-tsx": () => import("./../../../src/pages/jobs/bl0x.tsx" /* webpackChunkName: "component---src-pages-jobs-bl-0-x-tsx" */),
  "component---src-pages-jobs-boonty-tsx": () => import("./../../../src/pages/jobs/boonty.tsx" /* webpackChunkName: "component---src-pages-jobs-boonty-tsx" */),
  "component---src-pages-jobs-bykep-tsx": () => import("./../../../src/pages/jobs/bykep.tsx" /* webpackChunkName: "component---src-pages-jobs-bykep-tsx" */),
  "component---src-pages-jobs-punkme-tsx": () => import("./../../../src/pages/jobs/punkme.tsx" /* webpackChunkName: "component---src-pages-jobs-punkme-tsx" */),
  "component---src-pages-jobs-scorechain-tsx": () => import("./../../../src/pages/jobs/scorechain.tsx" /* webpackChunkName: "component---src-pages-jobs-scorechain-tsx" */),
  "component---src-pages-me-tsx": () => import("./../../../src/pages/me.tsx" /* webpackChunkName: "component---src-pages-me-tsx" */),
  "component---src-pages-world-tsx": () => import("./../../../src/pages/world.tsx" /* webpackChunkName: "component---src-pages-world-tsx" */)
}

